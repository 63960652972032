import React from 'react';
import PropTypes from 'prop-types';
import BaseContainer from 'components/grid/BaseContainer';
import sxPropType from 'propTypes/sx';

const SectionContainer = ({
  backgroundColor,
  backgroundImage,
  children,
  className,
  classes,
  disableGutters,
  leftContent,
  rightContent,
  containerProps = {},
  ...rest
}) => (
  <>
    <BaseContainer
      maxWidth="xl"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      className={className}
      disableGutters={disableGutters}
      classes={classes}
      leftContent={leftContent}
      rightContent={rightContent}
      containerProps={containerProps}
      {...rest}
    >
      {children}
    </BaseContainer>
  </>
);

SectionContainer.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  containerProps: PropTypes.shape({}),
  disableGutters: PropTypes.bool,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  sx: sxPropType,
};

SectionContainer.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  children: false,
  className: '',
  containerProps: {},
  disableGutters: false,
  leftContent: null,
  rightContent: null,
  sx: {},
};

export default SectionContainer;
