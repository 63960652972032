import { TRACK_EVENT } from '@tkww/the-bash-frontend';
import format from 'date-fns/format';
import { venueServiceSlugs } from 'components/VenueContactWizard/constants';
import {
  BUTTON_CTA_STYLE,
  CTA_CLICK,
  HYPERLINK_CTA_STYLE,
  ICON_CTA_STYLE,
  PROFILE_SUMMARY_CLICK_AREA,
  REVIEW_SECTION_CLICK_AREA,
  SERVICES_CLICK_AREA,
  TAG_CTA_STYLE,
} from 'analytics/constants';
import { AVAILABILITY_SECTION_CONTACT_TEXT } from 'constants/Profiles';

export const venueContactOpened = (profile) => ({
  type: TRACK_EVENT,
  name: 'Venue Contact Flow',
  properties: {
    memberId: profile.id,
    venueId: profile.venue.id,
    locationCity: profile.city.name,
    locationState: profile.city.stateOrProvince.abbreviation,
    stepCompleted: 'NULL',
    venueServicesOffered: profile.serviceAttributes.some((sa) =>
      venueServiceSlugs.includes(sa.slug)
    ),
    vendorPrimaryCategory: profile.primaryServiceType.personForm,
  },
});

export const venueContactStep = (profile, stepCompleted, additionalProperties = {}) => ({
  type: TRACK_EVENT,
  name: 'Venue Contact Flow',
  properties: {
    memberId: profile.id,
    venueId: profile.venue.id,
    locationCity: profile.city.name,
    locationState: profile.city.stateOrProvince.abbreviation,
    vendorPrimaryCategory: profile.primaryServiceType.personForm,
    stepCompleted,
    ...additionalProperties,
  },
});

export const venueContactSubmit = (profile, additionalProperties = {}) => ({
  type: TRACK_EVENT,
  name: 'Venue Contact Submit',
  properties: {
    memberId: profile.id,
    venueId: profile.venue.id,
    locationCity: profile.city.name,
    locationState: profile.city.stateOrProvince.abbreviation,
    vendorPrimaryCategory: profile.primaryServiceType.personForm,
    ...additionalProperties,
  },
});

export const relatedProfileTileClick = (profile) => ({
  type: TRACK_EVENT,
  name: 'Profile Tile Click',
  properties: {
    clickArea: 'Related Profiles',
    clickTarget: 'Related Profile Tile',
    profileName: profile.profileName,
  },
});

export const profileLoadMoreCtaClick = (clickArea) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea,
    clickTarget: 'Load More',
    ctaStyle: BUTTON_CTA_STYLE,
  },
});

export const profileCalendarContactCtaClick = (profile, selectedDate) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    memberId: profile.id,
    selectedDate: selectedDate.toLocaleDateString('en-US'),
    clickArea: 'Profile Calendar',
    clickTarget: AVAILABILITY_SECTION_CONTACT_TEXT,
    ctaStyle: BUTTON_CTA_STYLE,
  },
});

export const profileCalendarNextMonthClick = (currentMonth) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    selectedDate: format(currentMonth, 'MM yyyy'),
    clickArea: 'Profile Calendar',
    clickTarget: 'Calendar',
  },
});

export const profileReviewsRequestQuote = (clickArea, clickTarget, profile) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea,
    clickTarget,
    ctaStyle: BUTTON_CTA_STYLE,
    memberID: profile.id,
  },
});

export const jumpToCaretCtaClick = (clickTarget) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: PROFILE_SUMMARY_CLICK_AREA,
    clickTarget: `${clickTarget} section jump to`,
    ctaStyle: ICON_CTA_STYLE,
  },
});

export const vendorServiceTagClick = (serviceTypeName) => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: SERVICES_CLICK_AREA,
    clickTarget: serviceTypeName,
    ctaStyle: TAG_CTA_STYLE,
  },
});

export const seeAllReviewsClick = () => ({
  type: TRACK_EVENT,
  name: CTA_CLICK,
  properties: {
    clickArea: REVIEW_SECTION_CLICK_AREA,
    clickTarget: 'See All Reviews',
    ctaStyle: HYPERLINK_CTA_STYLE,
  },
});
