import flatten from 'lodash.flatten';
import sortBy from 'lodash.sortby';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const FULL_DATE = 'fullDate';

export const MONTH_ONLY = 'monthOnly';

export const venueServiceSlugs = [
  'kitchen-access',
  'coat-check',
  'lighting-system',
  'event-manager',
  'sound-system',
  'parking-staff',
  'stage',
  'security',
  'tableware',
  'tables-chairs',
  'beverage-services',
  'event-staffing',
];

export const cateringServiceSlugs = [
  'buffet',
  'chef-stations',
  'diy-food-bar',
  'family-style',
  'plated',
  'passed-appetizers',
];

export const layoutSlugs = ['cocktail-hour', 'seated', 'performance', 'meeting'];

export const contactMethods = {
  email: 'Email',
  phone: 'Phone',
  text: 'Text',
};

export const EVENT_TIMES = [
  '12:00PM',
  '12:30PM',
  '1:00PM',
  '1:30PM',
  '2:00PM',
  '2:30PM',
  '3:00PM',
  '3:30PM',
  '4:00PM',
  '4:30PM',
  '5:00PM',
  '5:30PM',
  '6:00PM',
  '6:30PM',
  '7:00PM',
  '7:30PM',
  '8:00PM',
  '8:30PM',
  '9:00PM',
  '9:30PM',
  '10:00PM',
  '10:30PM',
  '11:00PM',
  '11:30PM',
  '12:00AM',
  '12:30AM',
  '1:00AM',
  '1:30AM',
  '2:00AM',
  '2:30AM',
  '3:00AM',
  '3:30AM',
  '4:00AM',
  '4:30AM',
  '5:00AM',
  '5:30AM',
  '6:00AM',
  '6:30AM',
  '7:00AM',
  '7:30AM',
  '8:00AM',
  '8:30AM',
  '9:00AM',
  '9:30AM',
  '10:00AM',
  '10:30AM',
  '11:00AM',
  '11:30AM',
];

export const EVENT_DURATIONS = [
  {
    label: '30 minutes',
    value: '0.5',
  },
  {
    label: '1 hour',
    value: '1',
  },
  {
    label: '1.5 hours',
    value: '1.5',
  },
  {
    label: '2 hours',
    value: '2',
  },
  {
    label: '2.5 hours',
    value: '2.5',
  },
  {
    label: '3 hours',
    value: '3',
  },
  {
    label: '3.5 hours',
    value: '3.5',
  },
  {
    label: '4 hours',
    value: '4',
  },
  {
    label: '4.5 hours',
    value: '4.5',
  },
  {
    label: '5 hours',
    value: '5',
  },
  {
    label: '5.5 hours',
    value: '5.5',
  },
  {
    label: '6 hours',
    value: '6',
  },
  {
    label: '6.5 hours',
    value: '6.5',
  },
  {
    label: '7 hours',
    value: '7',
  },
  {
    label: '7.5 hours',
    value: '7.5',
  },
  {
    label: '8 hours',
    value: '8',
  },
  {
    label: '8.5 hours',
    value: '8.5',
  },
  {
    label: '9 hours',
    value: '9',
  },
  {
    label: '9.5 hours',
    value: '9.5',
  },
  {
    label: '10 hours',
    value: '10',
  },
  {
    label: '10.5 hours',
    value: '10.5',
  },
  {
    label: '11 hours',
    value: '11',
  },
  {
    label: '11.5 hours',
    value: '11.5',
  },
  {
    label: '12 hours',
    value: '12',
  },
];

export const EVENT_TYPE_EMOJIS = {
  bottle: '🍾',
  cake: '🎂',
  graduationCap: '🎓',
  balloon: '🎈',
  hearts: '💕',
  clinkingGlasses: '🥂',
  babyBottle: '🍼',
  heart: '❤️',
  turkey: '🦃',
  christmasTree: '🎄',
  halloween: '🎃',
  snowflake: '❄️',
  menorah: '🕎',
  fireworkSparkler: '🎇',
  fourLeafClover: '🍀',
  partyPopper: '🎉',
  leaves: '🍂',
  sun: '☀️',
  flower: '🌼',
  snowman: '⛄',
};

export const SEASONAL_EMOJIS = {
  December: EVENT_TYPE_EMOJIS.snowman,
  January: EVENT_TYPE_EMOJIS.snowman,
  February: EVENT_TYPE_EMOJIS.snowman,
  March: EVENT_TYPE_EMOJIS.flower,
  April: EVENT_TYPE_EMOJIS.flower,
  May: EVENT_TYPE_EMOJIS.flower,
  June: EVENT_TYPE_EMOJIS.sun,
  July: EVENT_TYPE_EMOJIS.sun,
  August: EVENT_TYPE_EMOJIS.sun,
  September: EVENT_TYPE_EMOJIS.leaves,
  October: EVENT_TYPE_EMOJIS.leaves,
  November: EVENT_TYPE_EMOJIS.leaves,
};

export const EVENT_TYPE_ROLES = {
  betrothed: 'Bride/Groom',
  friendRelativeOfBetrothed: 'Friend/Relative of the Bride/Groom',
  parent: 'Parent',
  partyHost: 'Party Host',
  guestOfHonor: 'Guest of Honor',
  friendRelativeOfGuestOfHonor: 'Friend/Relative of the Guest of Honor',
  corporate: 'Corporate Event Planner',
  professional: 'Professional Wedding/Event Planner',
  other: 'Other',
};

export const EVENT_TYPE_ROLES_SHARED = [EVENT_TYPE_ROLES.professional, EVENT_TYPE_ROLES.other];

export const EVENT_TYPE_BUCKETS = [
  {
    // Events for Adults
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      EVENT_TYPE_ROLES.corporate,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Anniversary Party',
        id: 139,
        sentenceForm: 'anniversary party',
        isPopular: true,
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Bachelor Party',
        id: 148,
        sentenceForm: 'bachelor party',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Bachelorette Party',
        id: 150,
        sentenceForm: 'bachelorette party',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Cocktail Party',
        id: 829,
        sentenceForm: 'cocktail party',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'College Reunion',
        id: 791,
        sentenceForm: 'college reunion',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Divorce Party',
        id: 2428,
        sentenceForm: 'divorce party',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'High School Reunion',
        id: 792,
        sentenceForm: 'high school reunion',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'New Years Eve Party',
        id: 775,
        sentenceForm: "New Year's Eve party",
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Private Party',
        id: 11,
        sentenceForm: 'private party',
        isPopular: true,
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Retirement Party',
        id: 877,
        sentenceForm: 'retirement party',
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
      {
        name: 'Valentines Day Party',
        id: 777,
        sentenceForm: "Valentine's Day party",
        emoji: EVENT_TYPE_EMOJIS.bottle,
      },
    ],
  },
  {
    // Adult Birthday
    defaultEmoji: EVENT_TYPE_EMOJIS.cake,
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      EVENT_TYPE_ROLES.corporate,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Birthday Party (Adult)',
        id: 133,
        sentenceForm: 'birthday party',
        isPopular: true,
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
    ],
  },
  {
    // Other Birthday Events
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.parent,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      EVENT_TYPE_ROLES.corporate,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Bar Mitzvah',
        id: 12,
        sentenceForm: 'bar mitzvah',
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
      {
        name: 'Bat Mitzvah',
        id: 137,
        sentenceForm: 'bat mitzvah',
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
      {
        name: 'Quinceanera',
        id: 795,
        sentenceForm: 'quinceanera',
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
      {
        name: 'Sweet 16 Party',
        id: 817,
        sentenceForm: 'sweet 16 party',
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
    ],
  },
  {
    // Kids' Birthdays
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.parent,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Birthday Party (Kids)',
        id: 747,
        sentenceForm: 'birthday party',
        isPopular: true,
        emoji: EVENT_TYPE_EMOJIS.cake,
      },
    ],
  },
  {
    // Events for Teenz
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.parent,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Graduation Party',
        id: 143,
        sentenceForm: 'graduation party',
        emoji: EVENT_TYPE_EMOJIS.graduationCap,
      },
      {
        name: 'Prom',
        id: 118,
        sentenceForm: 'prom',
        emoji: EVENT_TYPE_EMOJIS.balloon,
      },
    ],
  },
  {
    // Wedding Events
    roles: [
      EVENT_TYPE_ROLES.betrothed,
      EVENT_TYPE_ROLES.friendRelativeOfBetrothed,
      EVENT_TYPE_ROLES.parent,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Bridal Shower',
        id: 2442,
        sentenceForm: 'bridal shower',
        emoji: EVENT_TYPE_EMOJIS.hearts,
      },
      {
        name: 'Engagement Party',
        id: 2437,
        sentenceForm: 'engagement party',
        emoji: EVENT_TYPE_EMOJIS.hearts,
      },
      {
        name: 'Rehearsal Dinner',
        id: 2438,
        sentenceForm: 'rehearsal dinner',
        emoji: EVENT_TYPE_EMOJIS.hearts,
      },
      {
        name: 'Wedding',
        id: 5,
        sentenceForm: 'wedding',
        emoji: EVENT_TYPE_EMOJIS.hearts,
        isPopular: true,
      },
      {
        name: 'Wedding Ceremony',
        id: 818,
        sentenceForm: 'wedding ceremony',
        emoji: EVENT_TYPE_EMOJIS.hearts,
        isPopular: true,
      },
      {
        name: 'Wedding Cocktail Hour',
        id: 820,
        sentenceForm: 'cocktail hour',
        emoji: EVENT_TYPE_EMOJIS.hearts,
      },
      {
        name: 'Wedding Reception',
        id: 819,
        sentenceForm: 'wedding reception',
        emoji: EVENT_TYPE_EMOJIS.hearts,
        isPopular: true,
      },
    ],
  },
  {
    // Corporate Functions
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      EVENT_TYPE_ROLES.corporate,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Corporate Function',
        id: 14,
        emoji: EVENT_TYPE_EMOJIS.clinkingGlasses,
        isPopular: true,
        sentenceForm: 'corporate function',
      },
    ],
  },
  {
    // Family Events
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.parent,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.friendRelativeOfGuestOfHonor,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Baby Shower',
        id: 147,
        sentenceForm: 'baby shower',
        emoji: EVENT_TYPE_EMOJIS.babyBottle,
      },
      {
        name: 'Christening',
        id: 149,
        sentenceForm: 'christening',
        emoji: EVENT_TYPE_EMOJIS.heart,
      },
      {
        name: 'Easter Celebration',
        id: 764,
        sentenceForm: 'Easter celebration',
        emoji: EVENT_TYPE_EMOJIS.heart,
      },
      {
        name: 'Family Reunion',
        id: 790,
        sentenceForm: 'family reunion',
        emoji: EVENT_TYPE_EMOJIS.heart,
        isPopular: true,
      },
      {
        name: 'Fathers Day Party',
        id: 766,
        sentenceForm: "Father's Day party",
        emoji: EVENT_TYPE_EMOJIS.heart,
      },
      {
        name: 'First Communion',
        id: 2807,
        sentenceForm: 'first communion',
        emoji: EVENT_TYPE_EMOJIS.heart,
      },
      {
        name: 'Mothers Day Party',
        id: 765,
        sentenceForm: "Mother's Day party",
        emoji: EVENT_TYPE_EMOJIS.heart,
      },
      {
        name: 'Thanksgiving Celebration',
        id: 769,
        sentenceForm: 'Thanksgiving celebration',
        emoji: EVENT_TYPE_EMOJIS.turkey,
      },
    ],
  },
  {
    // Other Events
    roles: [
      EVENT_TYPE_ROLES.partyHost,
      EVENT_TYPE_ROLES.parent,
      EVENT_TYPE_ROLES.guestOfHonor,
      EVENT_TYPE_ROLES.corporate,
      ...EVENT_TYPE_ROLES_SHARED,
    ],
    eventTypes: [
      {
        name: 'Christmas Party',
        id: 656,
        sentenceForm: 'Christmas party',
        emoji: EVENT_TYPE_EMOJIS.christmasTree,
      },
      {
        name: 'Halloween Party',
        id: 770,
        sentenceForm: 'Halloween party',
        emoji: EVENT_TYPE_EMOJIS.halloween,
      },
      {
        name: 'Holiday Party',
        id: 2447,
        sentenceForm: 'holiday party',
        emoji: EVENT_TYPE_EMOJIS.snowflake,
      },
      {
        name: 'Hanukkah Celebration',
        id: 774,
        sentenceForm: 'Hanukkah celebration',
        emoji: EVENT_TYPE_EMOJIS.menorah,
      },
      {
        name: 'July 4th Party',
        id: 760,
        sentenceForm: 'July 4th party',
        emoji: EVENT_TYPE_EMOJIS.fireworkSparkler,
      },
      {
        name: "Saint Patrick's Day Party",
        id: 763,
        sentenceForm: "Saint Patrick's Day party",
        emoji: EVENT_TYPE_EMOJIS.fourLeafClover,
      },
      {
        name: 'Cinco De Mayo Party',
        id: 761,
        sentenceForm: 'Cinco de Mayo party',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
      {
        name: 'Mardi Gras Party',
        id: 776,
        sentenceForm: 'Mardi Gras party',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
      {
        name: 'Oktoberfest Party',
        id: 771,
        sentenceForm: 'Oktoberfest party',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
      {
        name: 'Community Event',
        id: 53,
        sentenceForm: 'community event',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
      {
        name: 'Fundraiser',
        id: 138,
        sentenceForm: 'fundraiser',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
      {
        name: 'Other',
        id: -2,
        sentenceForm: 'party',
        emoji: EVENT_TYPE_EMOJIS.partyPopper,
      },
    ],
  },
];

export const EVENT_TYPES = sortBy(
  flatten(
    EVENT_TYPE_BUCKETS.map((bucket) =>
      bucket.eventTypes.map((type) => ({
        ...type,
        isPopular: type.isPopular,
        roles: bucket.roles,
      }))
    )
  ),
  ['isPopular', 'name']
);
