export const LOGO_IMAGE = 'https://dd86mil3sc3or.cloudfront.net/logo/the-bash-header-logo.svg';

// AMP claims the publisher logo can be SVG but fails validation, the logo also needs to be of an exact size
// See https://developers.google.com/search/docs/data-types/article#logo-guidelines
export const LOGO_IMAGE_AMP =
  'https://dd86mil3sc3or.cloudfront.net/logo/the-bash-header-logo-amp.png';

export default {
  LOGO_IMAGE,
  LOGO_IMAGE_AMP,
};
