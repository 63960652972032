import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

const BaseContainer = ({
  backgroundColor,
  backgroundImage,
  children,
  className,
  disableGutters,
  maxWidth,
  leftContent,
  rightContent,
  containerProps = {},
  sx = {},
  ...rest
}) => (
  <Box
    sx={(theme) => ({
      backgroundColor: backgroundColor || 'background.default',
      backgroundImage,
      ...getSxAttributes(theme, sx),
    })}
    {...rest}
  >
    {leftContent && leftContent}
    <Container
      maxWidth={maxWidth}
      className={className}
      disableGutters={disableGutters}
      sx={(theme) => ({
        position: 'relative',
        zIndex: 1,
        ...getSxAttributes(theme, containerProps.sx || {}),
      })}
      {...containerProps}
    >
      {children}
    </Container>
    {rightContent && rightContent}
  </Box>
);

BaseContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  containerProps: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  disableGutters: PropTypes.bool,
  leftContent: PropTypes.node,
  maxWidth: PropTypes.string,
  rightContent: PropTypes.node,
  sx: sxPropType,
};

BaseContainer.defaultProps = {
  className: '',
  containerProps: {},
  backgroundColor: null,
  backgroundImage: null,
  disableGutters: false,
  leftContent: null,
  maxWidth: 'xl',
  rightContent: null,
  sx: {},
};

export default BaseContainer;
