import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import buildCanonicalTag from 'helpers/buildCanonicalTag';
import { gmConfig } from 'helpers/config';
import { LOGO_IMAGE } from 'constants/Logos';

export const PreloadMediaSettings = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
};

const getPreloadImageMediaProps = (mediaSetting) => {
  const mediaProps = {};
  switch (mediaSetting) {
    case PreloadMediaSettings.DESKTOP:
      mediaProps['media'] = 'min-width: 480px';
      break;
    case PreloadMediaSettings.MOBILE:
      mediaProps['media'] = 'max-width: 479.9px';
      break;
    default:
      break;
  }

  return mediaProps;
};

const MetaDataMarkup = ({
  title,
  description,
  openGraphProps,
  pageNumber,
  noindex,
  twitterImageAlt,
  desktopHeroImageUrl,
  mobileHeroImageUrl,
  includePinterestScript,
  preloadImages,
  canonicalUrlOverride,
}) => {
  const router = useRouter();

  return (
    <Head>
      <title>{title} - The Bash</title>
      {buildCanonicalTag(router.asPath, pageNumber, canonicalUrlOverride)}
      {noindex && <meta data-testid="robots" name="robots" content="noindex, follow" />}
      {description && <meta data-testid="description" name="description" content={description} />}
      <meta data-testid="og-type" name="og:type" content={openGraphProps.type} />
      <meta data-testid="og-title" name="og:title" content={openGraphProps.title} />
      <meta
        data-testid="og-description"
        name="og:description"
        content={openGraphProps.description}
      />
      <meta data-testid="og-url" name="og:url" content={openGraphProps.url} />
      <meta data-testid="og-image" name="og:image" content={openGraphProps.image || LOGO_IMAGE} />
      {openGraphProps.imageAlt && (
        <meta data-testid="og-image-alt" name="og:image:alt" content={openGraphProps.imageAlt} />
      )}
      {openGraphProps.location && (
        <meta data-testid="og-location" name="og:location" content={openGraphProps.location} />
      )}
      {openGraphProps.state && (
        <meta data-testid="og-state" name="og:state" content={openGraphProps.state} />
      )}
      {openGraphProps.country && (
        <meta data-testid="og-country" name="og:country" content={openGraphProps.country} />
      )}
      <meta data-testid="og-site-name" name="og:site_name" content="The Bash" />
      <meta data-testid="twitter-card" name="twitter:card" content="summary" />
      <meta data-testid="twitter-site" name="twitter:site" content="@thebash" />
      <meta data-testid="twitter-title" name="twitter:title" content={title} />
      {description && (
        <meta data-testid="twitter-description" name="twitter:description" content={description} />
      )}
      <meta data-testid="twitter-image-src" name="twitter:image:src" content={LOGO_IMAGE} />
      <meta
        data-testid="twitter-domain"
        name="twitter:domain"
        content={`https://${gmConfig.domains.www}`}
      />
      {twitterImageAlt && (
        <meta data-testid="twitter-image-alt" name="twitter:image:alt" content={twitterImageAlt} />
      )}
      {desktopHeroImageUrl && (
        <link rel="preload" as="image" href={desktopHeroImageUrl} media="(min-width: 480px)" />
      )}
      {mobileHeroImageUrl && (
        <link rel="preload" as="image" href={mobileHeroImageUrl} media="(max-width: 479.9px)" />
      )}
      {includePinterestScript && (
        <script
          defer
          src="//assets.pinterest.com/js/pinit.js"
          data-pin-build="initPinterestWidgets"
        />
      )}
      {preloadImages.map((imageDetails) => (
        <link
          key={imageDetails.src}
          rel="preload"
          as="image"
          href={imageDetails.src}
          {...getPreloadImageMediaProps(imageDetails.mediaSetting)}
        />
      ))}
    </Head>
  );
};

MetaDataMarkup.propTypes = {
  description: PropTypes.string,
  openGraphProps: PropTypes.shape({
    country: PropTypes.string,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    location: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  noindex: PropTypes.bool,
  pageNumber: PropTypes.number,
  title: PropTypes.string.isRequired,
  twitterImageAlt: PropTypes.string,
  desktopHeroImageUrl: PropTypes.string,
  mobileHeroImageUrl: PropTypes.string,
  includePinterestScript: PropTypes.bool,
  preloadImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      mediaSetting: PropTypes.oneOf([PreloadMediaSettings.MOBILE, PreloadMediaSettings.DESKTOP]),
    })
  ),
  canonicalUrlOverride: PropTypes.string,
};

MetaDataMarkup.defaultProps = {
  description: null,
  noindex: false,
  pageNumber: 1,
  twitterImageAlt: null,
  desktopHeroImageUrl: null,
  mobileHeroImageUrl: null,
  includePinterestScript: false,
  preloadImages: [],
  canonicalUrlOverride: null,
};

export default React.memo(MetaDataMarkup);
