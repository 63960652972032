export const CTA_CLICK = 'CTA Click';
export const COMPONENT_VIEWED = 'Component Viewed';
export const FILTER_APPLICATION = 'Filter Application';
export const FILTER_REMOVAL = 'Filter Removal';
export const FILTERED_SEARCH_RESULTS = 'Filtered Search Results';
export const PROFILE_TILE_CLICK = 'Profile Tile Click';
export const HIGH_LEVEL_SERVICE_TILE_CLICK = 'High-Level Service Tile Click';
export const SEE_MORE = 'See More';
export const FAQ_VIEWED = 'FAQ View';
export const LEARN_MORE = 'Learn More';
export const HYPERLINK_TARGET = 'hyperlink';

export const BUTTON_CTA_STYLE = 'button';
export const HYPERLINK_CTA_STYLE = 'hyperlink';
export const ICON_CTA_STYLE = 'icon';
export const TAB_CTA_STYLE = 'tab';
export const NAVIGATION_CTA_STYLE = 'navigation';
export const TAG_CTA_STYLE = 'tag';
export const DROPDOWN_CTA_STYLE = 'dropdown';

export const HERO_CLICK_AREA = 'Hero';
export const PROFILE_SUMMARY_CLICK_AREA = 'Profile Summary';
export const PROFILE_RELATED_SPACES_CLICK_AREA = 'Profile Related Spaces Tile';
export const STICKY_BAR_CLICK_AREA = 'Sticky Bar';
export const SEARCH_RESULTS_SET = 'Search Results Set';
export const PREMIUM_VENUES_CLICK_AREA = 'Premium Venues';
export const PREMIUM_VENUES_SUCCESS_CLICK_AREA = 'Premium Venues Success Page';
export const ARTICLE_BODY_CLICK_AREA = 'Body';
export const VENDOR_SPOTLIGHT_CLICK_AREA = 'Vendor Spotlight';
export const VIRTUAL_SERVICES_INFO_CLICK_AREA = 'Virtual Search Module';
export const ABOUT_SECTION_CLICK_AREA = 'About';
export const REVIEW_SECTION_CLICK_AREA = 'Reviews';
export const SERVICES_CLICK_AREA = 'Services';
export const SERVICES_AZLIST_CLICK_AREA = 'AZ List';
export const HIGH_LEVEL_SERVICE_TILE_CLICK_AREA = 'high-level service tiles';
export const RISING_STAR_CLICK_AREA = 'Rising Star Awards';

export const VENDOR_SPOTLIGHT_CLICK_TARGET = 'Vendor Spotlight Tile';
export const SEARCH_TILE_CLICK_TARGET = 'Search Tile';
export const VIEW_PROFILE_CLICK_TARGET = 'View Profile';
export const REQUEST_FREE_QUOTE_CLICK_TARGET = 'Request Free Quote';

export const STANDARD_SEARCH_RESULTS_CLICK_AREA = 'Standard Search Results';
export const OBSCURE_SEARCH_RESULTS_CLICK_AREA = 'Obscure Primary Results';
export const OBSCURE_OTHER_SEARCH_RESULTS_CLICK_AREA = 'Other Vendors Results';
export const OBSCURE_NATIONAL_SEARCH_RESULTS_CLICK_AREA = 'Obscure National Search Results';

export const PARTY_ENTRY_POINT_SUBMISSION = 'My Party Entry Point Submit';
export const EVENT_TILE_CLICK = 'Event Tile Click';
export const ARTICLE_TILE_CLICK = 'Article Tile Click';

export const RISING_STAR_CLICK_TARGET = (year) => `RSA Award ${year}`;

export const NOT_A_USER_ACTION = { nonInteraction: 1 };
