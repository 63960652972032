export const VENDOR_PROFILE = 'VENDOR_PROFILE';
export const VENUE_PROFILE = 'VENUE_PROFILE';

export const profileDisplayNames = Object.freeze({
  [VENDOR_PROFILE]: 'Vendor',
  [VENUE_PROFILE]: 'Venue',
});

export const contactCtaText = Object.freeze({
  [VENDOR_PROFILE]: 'Request Free Quote',
  [VENUE_PROFILE]: 'Check Availability',
});

export const NUM_REVIEWS_TO_LOAD_MORE = 3;
export const NUM_REVIEWS_TO_VIEW_MORE = 3;
export const NUM_EVENTS_TO_LOAD_MORE = 4;
export const NUM_PHOTOS_TO_LOAD_MORE = 20;

export const REVIEWS_PER_STANDARD_PAGE = 25;
export const REVIEWS_PER_WIDGET_PAGE = 3;
export const STANDARD_REVIEWS_PAGE = 'STANDARD_REVIEWS_PAGE';
export const WIDGET_REVIEWS_PAGE = 'WIDGET_REVIEWS_PAGE';
export const COMPACT_WIDGET_REVIEWS_PAGE = 'COMPACT_WIDGET_REVIEWS_PAGE';

export const PROFILE_SUMMARY_THUMBNAIL_SIZE_DESKTOP = 255;
export const PROFILE_SUMMARY_THUMBNAIL_SIZE_MOBILE = 379;

export const reviewTypes = Object.freeze({
  default: 'default',
  widget: 'widget',
  compact: 'compact',
});

export const ABOUT_SECTION_ID = 'about';
export const ABOUT_SECTION_VENDOR_TITLE = 'About Vendor';
export const ABOUT_SECTION_VENDOR_SUBTITLE = 'Learn more about this vendor for your event.';
export const ABOUT_SECTION_VENUE_TITLE = 'About Venue';
export const ABOUT_SECTION_VENUE_SUBTITLE = 'Learn more about this venue for your event needs.';

export const AWARDS_AND_BADGES_SECTION_ID = 'awards-and-badges';
export const AWARDS_AND_BADGES_SECTION_TITLE = 'Awards & Badges';
export const AWARDS_AND_BADGES_SECTION_SUBTITLE = 'See why this vendor is top rated on The Bash.';

export const AVAILABILITY_SECTION_ID = 'availability';
export const AVAILABILITY_SECTION_TITLE = 'Calendar';
export const AVAILABILITY_SECTION_SUBTITLE =
  'We recommend that you contact the vendor to confirm that the event date is open.';
export const AVAILABILITY_SECTION_CONTACT_TEXT = 'Contact Vendor';

export const BOOKED_EVENTS_SECTION_ID = 'booked-events';
export const BOOKED_EVENTS_SECTION_TITLE = 'Booked Events';

export const CATERING_SECTION_ID = 'catering';
export const CATERING_SECTION_TITLE = 'Catering Services';
export const CATERING_SECTION_SUBTITLE = 'Get a feel for the menu offerings and service options.';

export const PREMIUM_VENUES_SECTION_TITLE = 'Premium Venues You May Also Like...';

export const PROVIDED_SERVICES_SECTION_ID = 'services';
export const PROVIDED_SERVICES_SECTION_VENDOR_TITLE = 'Services';
export const PROVIDED_SERVICES_SECTION_VENUE_TITLE = 'Venue Services';
export const PROVIDED_SERVICES_SECTION_VENDOR_SUBTITLE =
  'View a list of services offered by this vendor.';
export const PROVIDED_SERVICES_SECTION_VENUE_SUBTITLE =
  'View a full list of amenities, staffing options and accessibility features.';

export const RELATED_PROFILES_SECTION_ID = 'related-profile';
export const RELATED_PROFILES_SECTION_TITLE = 'Related Profiles';
export const RELATED_PROFILES_SECTION_SUBTITLE = "Explore this vendor's other profiles.";

export const RELATED_SPACES_SECTION_ID = 'related-spaces';
export const RELATED_SPACES_SECTION_TITLE = 'Related Spaces';
export const RELATED_SPACES_SECTION_SUBTITLE = 'See other room options within this venue.';

export const REVIEWS_SECTION_ID = 'reviews';
export const REVIEWS_SECTION_TITLE = 'Reviews';
export const REVIEWS_SECTION_SUBTITLE =
  'Check out these verified reviews from bookings on The Bash.';

export const SONGS_SECTION_ID = 'songs';
export const SONGS_SECTION_TITLE = 'Song List';
export const SONGS_SECTION_SUBTITLE =
  'Get a feel for the songs performed by this vendor. Contact them directly for special requests.';

export const SUMMARY_SECTION_ID = 'summary';
