import React from 'react';
import { gmConfig } from 'helpers/config';

export const sanitize = (string) => {
  if (string.indexOf('?') === -1) {
    return string;
  }

  return string.substring(0, string.indexOf('?'));
};

const buildCanonicalTag = (pathname, pageNumber, canonicalUrlOverride) => {
  /* this mainly for properly displaying the canonical url when inspecting the DOM via devtools */
  let normalizePath = typeof window !== 'undefined' && window.location.pathname;

  if (pathname) {
    normalizePath = pathname;
  }

  const page = pageNumber > 1 ? `?page=${pageNumber}` : '';

  let href = `https://${gmConfig.domains.www}${sanitize(normalizePath)}${page}`;

  if (canonicalUrlOverride) {
    href = canonicalUrlOverride;
  }

  return (
    <link
      rel="canonical"
      href={href}
      // when this key is present, it only allows for one tag for each key which allows
      // this tag to be overwritten later if needed, e.g. by pagination
      key="canonical"
    />
  );
};

export default buildCanonicalTag;
